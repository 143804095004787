<template>
  <b-modal size="xl" centered v-model="show" hide-footer title="Otsi toodet">
    <loader :overlay="true" v-if="isLoading" />
    <b-row>
      <b-col md="7">
        <b-form @submit.prevent="search">
          <b-form-group class="mb-1">
            <b-input-group>
              <b-form-input
                class="form-control"
                v-model="form.value"
                size="sm"
                placeholder="Otsingusõna"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" size="sm" type="submit">
                  <font-awesome-icon icon="search" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <b-form-radio-group size="sm" v-model="form.type">
              <b-form-radio value="id">Toote ID</b-form-radio>
              <b-form-radio value="ean">EAN</b-form-radio>
              <b-form-radio value="pureva-code">Pureva kood</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <b-row v-if="products">
      <b-col>
        <b-table-simple bordered responsive small v-if="products.length">
          <b-tr variant="primary">
            <b-th style="width: 5%"></b-th>
            <b-th style="width: 30%">Nimi</b-th>
            <b-th>Laod</b-th>
          </b-tr>
          <b-tr v-for="product in products" :key="`p-${product.id}`">
            <b-td>
              <img v-bind:src="product.main_image" alt="" class="img-fluid" />
            </b-td>
            <b-td>
              <strong>
                {{ product.name }}
              </strong>
              <br />
              <template v-if="product.codes.length">
                <strong>Koodid:</strong> <br />
                <small>
                  <strong>SKU :</strong> {{ product.sku }} <br />
                  <ul class="list-unstyled small">
                    <li v-for="code in product.codes" :key="code.id">
                      <strong> {{ code.type }}: </strong>
                      {{ code.code }}
                    </li>
                  </ul>
                </small>
              </template>
            </b-td>
            <b-td>
              <b-table-simple>
                <b-tr variant="primary">
                  <b-th style="width: 10%;">
                    Nimi
                  </b-th>
                  <b-th style="width: 20%;">
                    Tarne
                  </b-th>
                  <b-th style="width: 15%;">
                    Laoseis
                  </b-th>
                  <b-th style="width: 15%;">
                    Hind
                  </b-th>
                  <b-th class="text-center" style="width: 10%;">
                    <b-button variant="outline-primary" size="sm" @click.prevent="selectProduct(product)">Vali toode</b-button>
                  </b-th>
                </b-tr>
                <b-tr v-for="stock in product.stocks" :key="`s-${stock.id}`">
                  <b-td>{{ stock.supplier.frontend_name }}</b-td>
                  <b-td>{{ stock.status ? stock.status.delivery_date : null | moment("DD.MM.YYYY") }}</b-td>
                  <b-td>{{ stock.in_stock }}</b-td>
                  <b-td>
                    <template v-if="stock.manual_price">
                      {{ stock.manual_price | currency }}
                      <small style="text-decoration: line-through;">
                        {{ stock.price | currency }}
                      </small>
                    </template>
                    <template v-else>
                      {{ stock.price | currency }}
                    </template>
                  </b-td>
                  <b-td class="text-center">
                    <b-button
                      variant="primary"
                      @click.prevent="selectStock(product, stock.id)"
                      >Vali</b-button
                    >
                  </b-td>
                </b-tr>
              </b-table-simple>
            </b-td>
          </b-tr>
        </b-table-simple>
        <b-alert v-else variant="warning" show>
          Tulemusi ei leitud!
        </b-alert>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { errorMessage } from "../lib/messages";
import RestService from "../lib/RestService";

export default {
  data() {
    return {
      isLoading: false,
      show: false,
      form: {
        value: null,
        type: "id",
      },
      products: null,
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    search() {
      this.isLoading = true;
      RestService.searchProductsViaCode(this.form).then((response) => {
        this.products = response.data.data;
        this.isLoading = false;
      }).catch((error) => {
          if (error.response) {
            errorMessage("Viga", error.response.data.message);
          }

          this.isLoading = false;
        });
    },
    selectStock(product, stockId) {
      let payload = {
        product: product,
        stock: product.stocks.find((s) => s.id == stockId),
      };

      this.$emit("selected", payload);

      this.show = false;
    },
    selectProduct(product) {
      this.$emit("selected-product", product);

      this.show = false;
    },
  },
};
</script>
