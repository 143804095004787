<template>
  <b-card :header="title">
    <b-form @submit.prevent="saveForm" class="position-relative">
      <loader v-if="isLoading" :overlay="true" />
      
      <b-form-group label="Pilt">
        <b-form-file
          v-model="formData.file"
          accept="image/*"
          size="sm"
          placeholder="Vali fail"
          drop-placeholder="Lohista fail siia"
        ></b-form-file>
        <b-form-invalid-feedback>

        </b-form-invalid-feedback>

        <div v-if="formData.image">
          <img :src="formData.image" class="img-fluid mt-3" alt="" />
        </div>
      </b-form-group>

      <hr>

      <b-form-group label="Pealkiri ET" class="mb-1">
        <b-form-input
          v-model="formData.title_et"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Link ET" class="mb-1">
        <b-form-input
          v-model="formData.link_et"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <hr>

      <b-form-group label="Pealkiri EN" class="mb-1">
        <b-form-input
          v-model="formData.title_en"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Link EN" class="mb-1">
        <b-form-input
          v-model="formData.link_en"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <hr>

      <b-form-group label="Pealkiri RU" class="mb-1">
        <b-form-input
          v-model="formData.title_ru"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Link RU" class="mb-1">
        <b-form-input
          v-model="formData.link_ru"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <div class="text-right mt-3">
        <b-button type="submit" variant="success">
          Salvesta
        </b-button>
      </div>
    </b-form>
  </b-card>
</template>

<script>
import RestService from "@/lib/RestService";

export default {
  props: {
    title: {},
    number: {},
  },
  data() {
    return {
      isLoading: false,
      formData: {
        file: null,
        image: null,
        title_et: null,
        link_et: null,
        title_en: null,
        link_en: null,
        title_ru: null,
        link_ru: null,
      }
    };
  },

  mounted() {
    this.getBoxData(this.number);
  },
  methods: {
    getBoxData(number) {
      this.isLoading = true;
      RestService.getHomeSettingsBox(number).then((response) => {
        this.formData = response.data
        this.isLoading = false;
      })
    },
    async saveForm() {
      this.isLoading = true;

      let file = null;
      if (this.formData.file) {
        file = await this.toBase64(this.formData.file);
      }

      RestService.postHomeSettingsBoxSave(this.number, {
        ...this.formData,
        ...{ file: file }
      })
        .then((response) => {
          this.formData = response.data
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false;
        });
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>
