import { HomeSettings } from "../components";

export default [
  {
    path: "/home-settings",
    component: HomeSettings,
    name: "home-settings",
    meta: {
      guest: false,
      needsAuth: true,
    },
  },
];
