<template>
  <div class="list-group list-group-flush">
    <sidebar-item routeName="dashboard" icon="tachometer-alt">
      Töölaud
    </sidebar-item>

    <sidebar-item
      v-if="userHasPermissionOnList(['pages', 'menus', 'banners', 'translations'])"
      routeName="pages" 
      icon="sticky-note"
    >
      Veeb

      <template v-slot:subitems>
        <sidebar-item
          v-if="userHasPermission('pages')"
          routeName="pages" 
          icon="angle-right" 
          iconColor="#414CFF"
        >
          Lehed
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('blog')"
          routeName="blog" 
          icon="angle-right" 
          iconColor="#414CFF"
        >
          Blogi
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('menus')"
          routeName="menus" 
          icon="angle-right" 
          iconColor="#414CFF"
        >
          Menüüd
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('banners')"
          routeName="banners"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Bännerid
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('contacts')"
          routeName="contacts"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Meeskond
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('translations')"
          routeName="translations"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Tõlked
        </sidebar-item>
      </template>
    </sidebar-item>

    <sidebar-item 
      v-if="userHasPermissionOnList(['orders', 'order-statuses', 'new-order', 'offers', 'discount-codes'])"
      routeName="orders" 
      icon="boxes"
    >
      Müük
      <template v-slot:subitems>
        <sidebar-item 
          v-if="userHasPermission('orders')"
          routeName="orders" 
          icon="angle-right" 
          iconColor="#414CFF"
        >
          Tellimused
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('order-statuses')"
          routeName="orderStatuses"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Tellimuste staatused
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('new-order')"
          routeName="order.new"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Uus tellimus
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('offers')"
          routeName="offers" 
          icon="angle-right" 
          iconColor="#414CFF"
        >
          Pakkumised
        </sidebar-item>
        
        <sidebar-item
          v-if="userHasPermission('discount-codes')"
          routeName="discount-codes"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Sooduskoodid
        </sidebar-item>
      </template>
    </sidebar-item>

    <sidebar-item
      v-if="userHasPermissionOnList(['products-search', 'products-index-search', 'stock-statuses', 'product-tracking', 'new-product', 'product-duplicates', 'product-merges'])"
      routeName="products-search" 
      icon="box-open"
    >
      Tooted
      <template v-slot:subitems>
        <sidebar-item
          v-if="userHasPermission('products-search')"
          routeName="products-search"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Andmebaasi otsing
        </sidebar-item>
        <sidebar-item
          v-if="userHasPermission('products-index-search')"
          routeName="products"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Indexi otsing
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('stock-statuses')"
          routeName="productStockStatuses"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Lao staatused
        </sidebar-item>
        <sidebar-item
          v-if="userHasPermission('product-tracking')"
          routeName="product-tracking"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Toodete jälgimine
        </sidebar-item>
        <sidebar-item
          v-if="userHasPermission('new-product')"
          routeName="products.new"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Uus toode
        </sidebar-item>
        <sidebar-item
          v-if="userHasPermission('product-duplicates')"
          routeName="products-auto-matching"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Duplikaatide otsimine
        </sidebar-item>
        <sidebar-item
          v-if="userHasPermission('product-merges')"
          routeName="products-merge"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Toodete merge
        </sidebar-item>
      </template>
    </sidebar-item>

    <sidebar-item
      v-if="userHasPermission('clients')"
      routeName="users" 
      icon="users"
    >
      Kliendid
    </sidebar-item>

    <sidebar-item
      v-if="userHasPermissionOnList(['suppliers', 'brands', 'catalog', 'categories', 'delivery-mapper', 'category-mapper'])"
      routeName="suppliers" 
      icon="map-signs"
    >
      Mappimine
      <template v-slot:subitems>
        <sidebar-item
          v-if="userHasPermission('suppliers')"
          routeName="suppliers"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Tarnijad
        </sidebar-item>
        <sidebar-item 
          v-if="userHasPermission('brands')"
          routeName="brands" 
          icon="angle-right" 
          iconColor="#414CFF"
        >
          Brändid
        </sidebar-item>
        <sidebar-item
          v-if="userHasPermission('catalog')"
          routeName="catalog"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Kataloog
        </sidebar-item>
        <sidebar-item
          v-if="userHasPermission('categories')"
          routeName="categories"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Kategooriad
        </sidebar-item>
        <sidebar-item
          routeName="product-categories"
          icon="angle-right"
          iconColor="#414CFF"
          v-if="false"
        >
          Toodete kat.
        </sidebar-item>
        <sidebar-item
          v-if="userHasPermission('delivery-mapper')"
          routeName="deliveryMapper"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Delivery mapper
        </sidebar-item>
        <sidebar-item
          v-if="userHasPermission('category-mapper')"
          routeName="categoryMapper"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Kategooria mapper
        </sidebar-item>
      </template>
    </sidebar-item>

    <sidebar-item
      v-if="userHasPermissionOnList(['administrators', 'admin-roles', 'shipping-zones', 'leases', 'emails', 'search-synonyms', 'import-status', 'import-logs', 'horizon'])"
      routeName="adminUsers" 
      icon="cogs"
    >
      Seaded
      <template v-slot:subitems>
        <sidebar-item
          v-if="userHasPermission('administrators')"
          routeName="adminUsers"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Administraatorid
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('admin-roles')"
          routeName="adminRoles"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Rollid
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('home-settings')"
          routeName="home-settings"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Avalehe seaded
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('shipping-zones')"
          routeName="shippingZones"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Transport
        </sidebar-item>

        <sidebar-item 
          v-if="userHasPermission('leases')"
          routeName="leases" 
          icon="angle-right" 
          iconColor="#414CFF"
        >
          Järelmaks
        </sidebar-item>

        <sidebar-item 
          v-if="userHasPermission('emails')"
          routeName="emails" 
          icon="angle-right" 
          iconColor="#414CFF"
        >
          Emailid
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('search-synonyms')"
          routeName="search-synonyms"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Otsingu sünonüümid
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('import-status')"
          routeName="import-status"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Impordi staatus
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('import-logs')"
          routeName="import-logs"
          icon="angle-right"
          iconColor="#414CFF"
        >
          Impordi logid
        </sidebar-item>

        <div v-if="userHasPermission('horizon')">
          <b-list-group-item
            variant="light"
            :href="horizonLink"
            target="_blank"
          >
            <span class="icon-badge mr-2">
              <font-awesome-icon
                icon="angle-right"
                :style="{ color: '#414CFF' }"
              />
            </span>
            Laravel Horizon
          </b-list-group-item>
        </div>
      </template>
    </sidebar-item>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem.vue";

export default {
  components: {
    SidebarItem,
  },
  data() {
    return {
      horizonLink: `${process.env.VUE_APP_API_HOST}/admin/horizon`,
    };
  },
  computed: {
    ...mapGetters({
      userHasPermission: "auth/userHasPermission",
      userHasPermissionOnList: "auth/userHasPermissionOnList",
    }),
  },
};
</script>
