<template>
  <b-card header="Otsingu pilt">
    <b-form @submit.prevent="saveForm" class="position-relative">
      <loader v-if="isLoading" :overlay="true" />
      
      <b-form-group label="Pilt">
        <b-form-file
          v-model="formData.file"
          accept="image/*"
          size="sm"
          placeholder="Vali fail"
          drop-placeholder="Lohista fail siia"
        ></b-form-file>
        <b-form-invalid-feedback>

        </b-form-invalid-feedback>

        <div v-if="formData.image">
          <img :src="formData.image" class="img-fluid mt-3" alt="" />
        </div>
      </b-form-group>

      <div class="text-right mt-3">
        <b-button type="submit" variant="success">
          Salvesta
        </b-button>
      </div>
    </b-form>
  </b-card>
</template>

<script>
import RestService from "@/lib/RestService";

export default {
  props: {},
  data() {
    return {
      isLoading: false,
      formData: {
        file: null,
        image: null,
      }
    };
  },

  mounted() {
    this.getSearchImageData();
  },
  methods: {
    getSearchImageData() {
      this.isLoading = true;
      RestService.getHomeSettingsSearchImage().then((response) => {
        this.formData = response.data
        this.isLoading = false;
      })
    },
    async saveForm() {
      this.isLoading = true;

      let file = null;
      if (this.formData.file) {
        file = await this.toBase64(this.formData.file);
      }

      RestService.postHomeSettingsSearchImageSave({
        ...this.formData,
        ...{ file: file }
      })
        .then((response) => {
          this.formData = response.data
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false;
        });
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>
