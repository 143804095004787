<template>
  <b-tr>
    <b-td>
      <router-link
        :to="{
          name: 'products.edit',
          params: { productId: productId },
        }"
      >
        {{ product.name }}
      </router-link>
      <b-button
        :href="productWebUrl"
        target="_blank"
        variant="outline-primary"
        size="sm"
        class="ml-2"
      >
        Ava veebis
      </b-button>
      <br />
      <b-row>
        <b-col md="6">
          <small>
            <template v-if="product.stock_name">
              <strong>Ladu: </strong> {{ product.supplier_name }} ({{
                product.stock_name
              }}) <br />
            </template>
            <template v-if="product.sku">
              <strong>SKU: </strong> {{ product.sku }} <br />
            </template>
            <template v-if="product.all_codes">
              <div v-for="(code, codeKey) in product.all_codes" :key="codeKey">
                <strong>{{ codeKey }}:</strong> {{ code }}
              </div>
            </template>
            <template v-else>
              <template v-if="product.ean">
                <strong>EAN: </strong> {{ product.ean }} <br />
              </template>
              <template v-if="product.mpn">
                <strong>MPN: </strong> {{ product.mpn }} <br />
              </template>
            </template>

            {{ product.info }}
          </small>
        </b-col>
        <b-col md="6">
          <small>
            <strong>Kaup laos: </strong>
            <a
              href="#"
              @click.prevent="updateStatus(product, 'arrived_in_stock')"
            >
              <b-badge variant="success" v-if="product.arrived_in_stock">
                Jah
              </b-badge>
              <b-badge variant="danger" v-else>Ei</b-badge>
            </a>
            <br />
            <strong>Kaup väljastatud: </strong>
            <a href="#" @click.prevent="updateStatus(product, 'sent_out')">
              <b-badge variant="success" v-if="product.sent_out">Jah</b-badge>
              <b-badge variant="danger" v-else>Ei</b-badge>
            </a>
            <br />
            <template>
              <strong>Kaup tellitud: </strong>
              <a href="#" @click.prevent="updateStatus(product, 'is_ordered')">
                <b-badge variant="success" v-if="product.is_ordered">
                  Jah
                </b-badge>
                <b-badge variant="danger" v-else>Ei</b-badge>
              </a>
            </template>
          </small>
        </b-col>
      </b-row>

      <div v-if="importProduct">
        <hr class="my-1">
        Lao aadress: {{ importProduct.stock_address ? importProduct.stock_address : '-' }} <br>
        <small>
          <div v-for="stock in importProduct.stocks" :key="stock.id">
            <strong>{{ stock.supplier.name }} ({{ stock.supplier.frontend_name }}):</strong> {{ stock.in_stock }}
          </div>
        </small>
      </div>
    </b-td>
    <b-td class="text-center">
      {{ product.delivery_date }}
      <ButtonDatePicker
        v-if="!list"
        class="product-delivery-button"
        v-model="newDeliveryDate"
        @input="updateDeliveryDate"
      />
    </b-td>
    <b-td
      class="text-center"
      :class="{ 'qty-highlight table-danger': product.quantity > 1 }"
    >
      {{ product.quantity }}
    </b-td>
    <b-td class="text-center">
      <span class="old-price" v-if="product.discount">
        <template v-if="product.discount.item_price_subtotal < 0.1">
          {{ product.discount.item_price_subtotal | currency3 }}&nbsp;&euro;
        </template>
        <template v-else>
          {{ product.discount.item_price_subtotal | currency3 }}&nbsp;&euro;
        </template>
      </span>
      <br />
      <span v-b-tooltip.hover.noninteractive title="Ilma käibemaksuta">
        <template v-if="product.item_price_subtotal < 0.1">
          {{ product.item_price_subtotal | currency3 }}&nbsp;&euro;
        </template>
        <template v-else>
          {{ product.item_price_subtotal | currency }}&nbsp;&euro;
        </template>
      </span>
      <br>
      <small v-b-tooltip.hover.noninteractive title="Koos käibemaksuga">
        <template v-if="product.item_price < 0.1">
          {{ product.item_price | currency3 }}&nbsp;&euro;
        </template>
        <template v-else>
          {{ product.item_price | currency }}&nbsp;&euro;
        </template>
      </small>
    </b-td>
    <b-td class="text-center">
      <span class="old-price" v-if="product.discount">
        {{ product.discount.price_subtotal | currency }}&nbsp;&euro;
      </span>
      <br />
      <span v-b-tooltip.hover.noninteractive title="Ilma käibemaksuta">
        {{ (product.item_price_subtotal * product.quantity) | currency }}&nbsp;&euro;
      </span> 
      <br />
      <small v-b-tooltip.hover.noninteractive title="Koos käibemaksuga">
        {{ (product.item_price * product.quantity) | currency }}&nbsp;&euro;
        <br />
      </small>
      <slot></slot>
    </b-td>
  </b-tr>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonDatePicker from "@/components/ButtonDatePicker";

export default {
  props: {
    product: {
      type: Object,
    },
    list: {
      type: Boolean,
      default: false,
    },
    importProducts: {
      default: null,
    },
  },
  components: {
    ButtonDatePicker,
  },
  data() {
    return {
      newDeliveryDate: null,
    };
  },
  created() {
    if (this.product.delivery_date_iso) {
      this.newDeliveryDate = this.product.delivery_date_iso;
    }
  },
  computed: {
    ...mapGetters({
      webHostUrl: "global/webHostUrl",
    }),
    productWebUrl() {
      return `${this.webHostUrl}/et/toode/redirect-${this.product.sku}`;
    },
    productId() {
      return this.product.sku.substring(2);
    },
    importProduct() {
      if (!this.importProducts) {
        return null;
      }

      return this.importProducts.find((i) => i.sku == this.product.sku);
    },
  },
  methods: {
    updateStatus(product, type) {
      this.$emit("updateStatus", {
        id: product.id || null,
        stock_id: product.stock_id,
        type: type,
      });
    },
    updateDeliveryDate(value) {
      this.$emit("updateDeliveryDate", {
        id: this.product.id || null,
        delivery_date: value,
      });
    },
  },
};
</script>

<style lang="scss">
.qty-highlight {
  color: red;
  font-size: 24px;
}

.old-price {
  color: #686868;
  font-size: 12px;
  text-decoration: line-through;
}

.product-delivery-button button {
  font-size: 10px;
  padding: 2px 3px;
}
</style>
