<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Avalehe seaded
    </h1>

    <b-row>
      <b-col md="6">
        <SearchImage></SearchImage>
      </b-col>
      <b-col md="6">

      </b-col>
    </b-row>

    <hr>

    <b-row>
      <b-col md="4">
        <HomeBlock title="Kast 1" :number="1"></HomeBlock>
      </b-col>
      <b-col md="4">
        <HomeBlock title="Kast 2" :number="2"></HomeBlock>
      </b-col>
      <b-col md="4">
        <HomeBlock title="Kast 3" :number="3"></HomeBlock>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import HomeBlock from './HomeBlock.vue';
import SearchImage from './searchImage.vue';

export default {
  components: {
    HomeBlock,
    SearchImage
  },
  data() {
    return {
      isLoading: false,
      form: {

      }
    };
  },

  mounted() {
  },
  methods: {
    
  },
};
</script>
